@media (max-width: 432px) {
  .advertisementMargin {
    margin-top: -$generalCardsPadding;
    margin-left: -$generalCardsPadding;
    margin-right: -$generalCardsPadding;
  }
}

@media (min-width: 433px) {
  .advertisementMargin {
    margin: auto;
  }
}

.advertisementImageGalleryWrapperGeneral {
  max-width: 500px;
  max-height: 500px;
  position: relative;
}
