#mainWrapper {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: stretch;
}

#root {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
}
