#breadCrumbsContainer{
    @media screen and (max-width: 600px)  {
        padding-left: $advertisementCardsPaddingLeftSmallScreen !important;
        padding-right: $advertisementCardsPaddingRightSmallScreen !important
    }
}

.breadcrumb {
    margin-bottom: 0;
    padding-bottom: 15px;
}