$sidebarMainItemsWording: 200px;

$mainIconSidebar: 50px;

$sidebarWidthClosed: $mainIconSidebar;

$sidebarWidthOpen: $sidebarMainItemsWording + $mainIconSidebar;

$advertisementCardsPaddingLeftSmallScreen: 12px;

$advertisementCardsPaddingRightSmallScreen: 12px;

$mainBodyPaddingTop: 15px;

$mainBodyPaddingBottom: 15px;

$mainBodyPaddingLeftLargeScreen: 20px;

$mainBodyPaddingRightLargeScreen: 20px;

$generalCardsPadding: 8px;
