#sidebar {
  display: block;
  color: #fff;
  background-color: #1e90ff;
  z-index: 1;
  position: -webkit-sticky;
  top: 0;
  position: sticky;
  height: 100vh;
  z-index: 999;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button {
  padding-left: 10px;
}

.accordion-button:not(.collapsed) {
  background-color: white;
  box-shadow: none;
  color: black;
}

.sidebarIcons {
  color: white;
  font-size: 22px;
  position: relative;
}

.sidebarItemContainer {
  display: flex;
  // border-top: 1px solid white;
  border-bottom: 1px solid white;
  border-top: none;
}

.topBorderFirstItem {
  border-top: 1px solid white !important;
}

.sidebarItemWrapper {
  cursor: pointer;
  min-height: 44px;
  // margin-bottom: 10px;
}

.sidebarItemWrapperTitle {
  display: flex;
  align-items: center;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* conqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version*/
}

.mainIconSidebar {
  width: $mainIconSidebar;
  display: flex;
  justify-content: center;
  min-height: 44px;
  align-items: center;
}
.mainIconSidebar:hover {
  cursor: pointer;
}

.sidebarMainItemsWording {
  width: $sidebarMainItemsWording;
}

.categoryItems {
  padding-top: 7px;
  padding-left: 2px;
  padding-bottom: 7px;
  font-size: 0.9rem;
}

.categoryItems:hover {
  cursor: pointer;
}

.hidden {
  display: none;
}

.rotate180degrees {
  transform: rotate(180deg);
}

.rotate180degreesFaAngleUp {
  transform: rotate(180deg);
  padding-left: 7px;
  display: flex;
  align-items: center;
}

.whitishColor {
  background-color: #ffffff1f;
}

.rotate180degreesFaAngleDown {
  transform: rotate(360deg);
  padding-right: 7px;
  display: flex;
  align-items: center;
}

#mainSidebarButton {
  transition: all 400ms ease;
  will-change: transform, opacity;
}

.collapse, .collapsing {
  will-change: transform, opacity, left, top, right, bottom, width, height, color, background-color, border-color, margin, padding, font-size, font-weight, z-index;
}