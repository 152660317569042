.generalCardLayoutWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  justify-content: space-between;
  grid-gap: 30px;
  @media screen and (max-width: 900px) {
    // justify-content: space-around;
  }
  @media screen and (max-width: 600px) {
    padding-left: $advertisementCardsPaddingLeftSmallScreen;
    padding-right: $advertisementCardsPaddingRightSmallScreen;
  }
}

.googleMapShowAdvertisementWrapper {
  @media screen and (max-width: 600px) {
    max-width: 100% !important;
  }
}

.pointerOnHover:hover {
  cursor: pointer;
}

.textNotSelectable {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.hidden {
  display: none !important;
}

.imageUploadThumbnail {
  height: 150px;
  border: 1px solid rgb(165, 165, 165);
  margin: 0px 10px 10px 0;
}

.image-gallery-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  background-color: black;
}

.general-columns {
  @media screen and (max-width: 600px) and (min-width: 350px) {
    width: calc(100vw - $sidebarWidthClosed);
  }
}

.general-cards {
  padding: 15px;
  @media screen and (max-width: 600px) and (min-width: 350px) {
    padding: $generalCardsPadding;
  }
}

.containerGeneral {
  @media screen and (min-width: 601px) {
    padding: 0px !important;
  }
}

.rowWithGutter {
  --bs-gutter-y: 24px;
  --bs-gutter-x: 24px;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.text-truncate-container {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;
}

.semiWhiteBackground {
  background: rgba(0, 0, 0, 0.249);
}

@keyframes colorChange {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.text-with-background {
  background: linear-gradient(90deg, #f0f0f0, #c4c4c4, #f0f0f0);
  background-size: 200% 200%;
  color: transparent;
  animation: colorChange 1s linear infinite alternate;
}

.form-check-label {
  font-size: 13px;
}

.form-check-label:hover {
  cursor: pointer;
}

.form-check-input {
  padding: 8px;
}

.form-check-input:hover {
  cursor: pointer;
}

.grecaptcha-badge {
  // visibility: hidden;
  display: none !important;
}

.form-check-label {
  color: #212529 !important;
}

body {
  height: 100vh;
  margin: 0;
  padding: 0;
}
