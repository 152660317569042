#mainBody {
  width: 100%;
  transition: all 0.3s;
  padding-top: $mainBodyPaddingTop;
  padding-bottom: $mainBodyPaddingBottom;
  padding-left: $mainBodyPaddingLeftLargeScreen;
  padding-right: $mainBodyPaddingRightLargeScreen;
  will-change: transform, opacity;
}

@media only screen and (max-width: 600px) {
  #mainBody {
    padding-right: 0;
    padding-left: $sidebarWidthClosed;
  }
  #sidebar {
    position: fixed;
    height: 100%;
    z-index: 999;
  }
  .loginButtons {
    font-size: 0.95rem !important;
  }
}
