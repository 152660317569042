#navbarMain {
  background-color: #1e90ff;
  border-bottom: 1px solid white;
}

.navbar-nav {
  flex-direction: row !important;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
