#advertisementCreateContainer {
    padding: 10px !important;
    @media screen and (max-width: 600px) {
        padding: 0px !important
    }
}

// .advertisementCreateCards {
//     padding: 20px !important;
//     @media screen and (max-width: 600px) {
//         padding: 5px !important
//     }
// }