table {
  margin-bottom: 0 !important;
}

@media only screen and (min-width: 601px) {
  .scrollable-table-sidebar-open {
    max-width: calc(100vw - ($sidebarWidthOpen + $sidebarWidthClosed));
  }

  .scrollable-table-sidebar-close {
    max-width: calc(100vw - ($sidebarWidthClosed + $sidebarWidthClosed));
  }
}

.table-responsive {
  padding: 0 !important;
}

.table-content-cell-center {
  text-align: center;
}
